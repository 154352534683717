/**
 * Checks if sessionStorage is available and functional.
 * @returns {boolean} - True if accessible, false otherwise.
 */
const isSessionStorageAvailable = () => {
  try {
    const testKey = '__test__';
    const testValue = 'test';
    window.sessionStorage.setItem(testKey, testValue);
    const readValue = window.sessionStorage.getItem(testKey);
    if (readValue !== testValue) {
      return false;
    }
    window.sessionStorage.removeItem(testKey);
    return true;
  } catch {
    return false;
  }
};

/**
 * Utility function to get an item from sessionStorage safely.
 * @param {string} key - The sessionStorage key.
 * @param {*} defaultValue - The default value to return if key is not found or access fails.
 * @returns {*} - The parsed value from sessionStorage or the default value.
 */
const getSessionItem = (key, defaultValue) => {
  try {
    const value = window.sessionStorage.getItem(key);
    return value !== null ? JSON.parse(value) : defaultValue;
  } catch {
    return defaultValue;
  }
};

/**
 * Utility function to set an item in sessionStorage safely.
 * @param {string} key - The sessionStorage key.
 * @param {*} value - The value to store.
 */
const setSessionItem = (key, value) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

export {
  isSessionStorageAvailable,
  getSessionItem,
  setSessionItem,
};
