import React, { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { useRollbar } from '@rollbar/react';

import lazyRetry from './utils/lazyRetry';

function App() {
  const [searchParams] = useSearchParams();
  global.mkey = searchParams.get('mkey');
  const rollbar = useRollbar();

  const PtInitiatedIntakePage = React.lazy(() => lazyRetry(() => import('./intake/PtInitiatedIntakePage'), 'PtInitiatedIntakePage', rollbar));
  const IntakeApp = React.lazy(() => lazyRetry(() => import('./intake/IntakeApp'), 'IntakeApp', rollbar));
  const IntakeComponent = global.mkey ? IntakeApp : PtInitiatedIntakePage;

  // track the number of times the app has been reloaded
  const reloads = Number(searchParams.get('reloads'));
  global.reloads = Number.isNaN(reloads) ? 0 : reloads;

  return (
    <Suspense
      fallback={(
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    >
      <IntakeComponent />
    </Suspense>
  );
}

export default App;
