/**
 * Utility function to get a query parameter value by key.
 * @param {string} key - The name of the query parameter.
 * @returns {string|null} - The value of the query parameter or null if not found.
 */
const getQueryParam = (key) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

/**
 * Utility function to set or update a query parameter without reloading the page.
 * @param {string} key - The name of the query parameter.
 * @param {string} value - The value to set for the query parameter.
 */
const setQueryParam = (key, value) => {
  const url = new URL(window.location);
  url.searchParams.set(key, value);
  window.history.replaceState(null, '', url.toString());
};

/**
 * Utility function to remove a query parameter without reloading the page.
 * @param {string} key - The name of the query parameter.
 */
const removeQueryParam = (key) => {
  const url = new URL(window.location);
  url.searchParams.delete(key);
  window.history.replaceState(null, '', url.toString());
};

export {
  getQueryParam,
  setQueryParam,
  removeQueryParam,
};
